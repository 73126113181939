// import 'lib/calltouch'
import {registerRoot} from 'lib/lazy'
import registerServiceWorker from 'lib/registerServiceWorker'
import {
    applyViewport,
    // maxPageWidth,
    minPageWidth,
} from 'lib/styles'
import 'resources/main.less'

registerRoot(
    {
        buyout: () => import('landings/Buyout'),
        default: () => import('App'),
    },
    'root',
    (r: any) => module.hot.accept(['App', 'landings/Buyout'], r),
)
// applyViewport(maxPageWidth)
applyViewport(minPageWidth)

registerServiceWorker()
