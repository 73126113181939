export default {
    lightGray1: '#EDF2F7',
    lightGray2: '#A6BFCC',
    lightGray3: '#f4f7fa',
    orange: '#F36F21',
    orangeShadow: '#C9571A',
    yellow: '#F7C45E',

    burgundy: '#96004F',
    burgundyShadow: '#79003D',
    colorText: '#4C5F66',
    colorTextLight: '#849195',
    darkViolet: '#3E3E50',
    teal: '#00AAAD',
    tealShadow: '#248F94',
    lightGray: '#E7E6E4',
    black: '#000',
    white: '#fff',


    grayBlue: '#5C7580',
    greenBright: '#C9DC49',
    // lightGreen: '#C9DB4A',
    // lightGreen2: '#C9DC49',
    greenShadow: '#A0B322',

    primary: '#96004F',
    darkNavy: '#333F4E',
    textGray: '#8E8E8E',
    gray: '#596B88',
    darkGray: '#202020',
    blueGray: '#0589CD',
    blue: '#0F80EA',
    malina: '#FB2A67',
    malinaLight: '#FC5787',
    green: '#2BD663',
}
