// import color from 'color' // https://github.com/qix-/color

import _colors from './colors'
export const colors = _colors

export const important = (style: string) => style + ''

// export const center = (width: number) => ({
//     maxWidth: width,
//     margin: 'auto',
// })

export const column = (ratio: number, cycle: number = 0) => ({
    width: `calc(${99.9 * ratio}% - ${30 - 30 * ratio}px)`,
    ':nth-child(1n)': {
        float: 'left',
        marginRight: 30,
        clear: 'none',
    },
    ':last-child': {
        marginRight: 0,
    },
    [`:nth-child(${cycle}n)`]: {
        marginRight: 0,
        float: 'right',
    },
    [`:nth-child(${cycle}n + 1)`]: {
        clear: 'both',
    },
    ':nth-child(3n + 1)': {
        clear: 'left',
    },
})

export const maxPageWidth = 1280
export const minPageWidth = 1000
export const contentWidth = 980

export const sideBarWidth = 307

export const commonStyles = {
    bold: {
        fontWeight: 700,
    },
    button: {
        borderRadius: 'none',
        cursor: 'pointer',
        onHover: {
            backgroundColor: '#5FC9ED',
        },
        textDecoration: 'none',
        transition: 'ease-out 0.35s',
    },
    content: {
        margin: '0 auto',
        width: contentWidth,
    },
    contentWrapper: {
        backgroundColor: 'transparent',
        margin: '0 auto',
        maxWidth: maxPageWidth,
        minWidth: minPageWidth,
        padding: 0,
        width: '100%',
    },
    interParagraph: {
        paddingBottom: 15,
    },
    link: {
        // color: colors.lightGray2,
        color: colors.white,
        cursor: 'pointer',
        fontWeight: 300,
        textDecoration: 'none',
        // textTransform: 'uppercase',
    },
    pageWrapper: {
        fontFamily: 'Rubik, sans-serif',
        left: 0,
        margin: 'auto',
        minHeight: '100%',
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    title: {
        fontSize: '30px',
        padding: '18px 0',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    textLightGray: {
        color: colors.colorTextLight,
    },
}
export default commonStyles

/* Set mobile viewport */
let resizeHandler: any
export function applyViewport(minWidth: number) {
    const viewportMeta = document.getElementsByName('viewport')[0]
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    if(!isMobile || !viewportMeta) {
        return
    }

    console.log('Mobile device detected.')
    /* Change ratio if width is smaller than minimum size */
    const mozilla = navigator.appName === 'Netscape' || !navigator.webkitPersistentStorage
    const ratio = window.screen.width < minWidth
        ? (window.screen.width / (minWidth + 2)).toFixed(4)
        : 1
    viewportMeta.setAttribute(
        'content',
        `width=${mozilla ? 'device-width' : minWidth}, initial-scale=${ratio}, minimum-scale=${ratio}, user-scalable=yes, shrink-to-fit=no`,
    )

    if(!resizeHandler) {
        /* Update viewport scale if screen dimentions changed */
        resizeHandler = window.addEventListener('resize', function() {applyViewport(minWidth)})
    }
}
