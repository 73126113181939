// import Styler from 'lib/styler' /** @jsx Styler.createElement */
import React from 'react'

// import {observable} from 'mobx'
// import {observer} from 'mobx-react'
import {AppContainer} from 'react-hot-loader'
// import {render} from 'react-dom'
import {render} from 'react-snapshot'

interface LazyProps {
    _load: () => Promise<{default: React.ComponentType}>,
    defaultComponent?: React.ComponentType,
}

export class Lazy extends React.Component<LazyProps> {
    public state = {
        Component: () => <div style={{display: 'none'}} />,
    }

    public async componentDidMount() {
        const {_load, defaultComponent} = this.props
        if(defaultComponent) this.setState({Component: defaultComponent})
        try {
            // console.log('Lazy/componentDidMount()', (await _load()).default)
            this.setState({Component: (await _load()).default})
        } catch(e) {
            console.error(e.message)
        }
    }

    public render() {
        const props = {...this.props}
        const {Component} = this.state
        delete props._load
        return <Component {...props} />
    }
}

const lazyComponent = (
    load: () => Promise<{default: React.ComponentType}>,
    defaultComponent?: React.ComponentType,
) => (props: any) => <Lazy {...props} _load={load} defaultComponent={defaultComponent} />
export default lazyComponent

function lazyImportApp(choices: any) {
    return lazyComponent(choices[process.env.REACT_APP_PROJECT] || choices.default)
}

export function registerRoot(choices: any, elementId: string, hotFn: any) {
    const App = lazyImportApp(choices)
    const renderFn = () => render(
        <AppContainer><App /></AppContainer>,
        document.getElementById(elementId),
    )
    renderFn()
    if(module.hot) hotFn(renderFn)
}
